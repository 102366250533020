<template>
    <div>
        <st-page :title="title">
            <loader v-if="isLoading"/>
            <template #toolbar>
                <applications-list-toolbar :canSign="true"  @refreshApplicationsList="refreshApplicationsList"/>
            </template>
            <applications-list-filter :assigned="true" ref="applicationsListFilter">
                <applications-list-table :assigned="true" />
            </applications-list-filter>
        </st-page>
        <portal to="beneficiary-custom-field">
            <template slot-scope="{data}">
                <ApplicationTableCustomField
                    fieldType="beneficiary"
                    :items="data.item.form.beneficiary_users"
                />
            </template>
        </portal>
        <portal to="locality-custom-field">
            <template slot-scope="{data}">
                <ApplicationTableCustomField
                    fieldType="locality"
                    :items="data.item.form.real_estate_target"
                />
            </template>
        </portal>
    </div>
</template>

<script>
import ApplicationsListFilter from '../components/ApplicationsListFilter';
import ApplicationsListTable from '../components/ApplicationsListTable';
import ApplicationsListToolbar from '../components/ApplicationsListToolbar';
import ApplicationTableCustomField from '../components/ApplicationTableCustomField';
import { ApplicationsPermissions } from '@/modules/applications/applications-permissions';
import { mapGetters } from "vuex";

export default {
    name: 'ApplicationsAssignedList',
    components: {
        ApplicationsListFilter,
        ApplicationsListTable,
        ApplicationsListToolbar,
        ApplicationTableCustomField
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            currentUser: 'auth/currentUser',
        }),
        isLoading() {
            return this.loading['applications/get'];
        },
        title(){
            return this.hasPermissionToReadStaff ? this.$t('APPLICATION.LIST.TITLE_STAFF') : this.$t('APPLICATION.LIST.TITLE');
        },
        hasPermissionToReadStaff() {
            return new ApplicationsPermissions(this.currentUser).readStaff;
        },
    },
    methods: {
        refreshApplicationsList() {
            this.$refs['applicationsListFilter'].refresh();
        }
    }
}
</script>
